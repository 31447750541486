export const calcElementPosition = (event, mobileEvents, targetSpan) => {
  if (mobileEvents.isTargetTouch) {
    return;
  }
  let x = event.clientX;
  let y = event.clientY;

  if (!targetSpan) return;

  let windowWidth = window.innerWidth;
  let windowHeight = window.innerHeight;
  const rect = targetSpan.getBoundingClientRect();
  let newLeft, newTop, newTooltipX, newTooltipY;

  if (windowWidth > 767) {
    targetSpan.style.width = '';
    if (rect.x < x) {
      newTooltipX = x - rect.x;
      newLeft = (parseFloat(targetSpan.style.left) || 0) + newTooltipX;
    } else {
      newTooltipX = rect.x - x;
      newLeft = (parseFloat(targetSpan.style.left) || 0) - newTooltipX;
    }
    if (rect.y < y) {
      newTooltipY = y - rect.y;
      newTop = (parseFloat(targetSpan.style.top) || 0) + newTooltipY;
    } else {
      newTooltipY = rect.y - y;
      newTop = (parseFloat(targetSpan.style.top) || 0) - newTooltipY;
    }
    
    // снизу
    if (y + rect.height > windowHeight) {
      newTop = y - rect.height + 15
    }
    // справа
    if (x + rect.width > windowWidth) {
      newLeft = x - rect.width - 30
    }
    targetSpan.style.left = newLeft + 15 + 'px';
    targetSpan.style.top = newTop - 15 + 'px';
  } else {
    // from mobile
    targetSpan.style.width = windowWidth - 20 + 'px';
    const rect = targetSpan.getBoundingClientRect();
    
    if (rect.x < 2) {
      newTooltipX = 2 - rect.x;
      newLeft = (parseFloat(targetSpan.style.left) || 0) + newTooltipX;
    } else {
      newTooltipX = rect.x - 2;
      newLeft = (parseFloat(targetSpan.style.left) || 0) - newTooltipX;
    }
    if (rect.bottom < y) {
      newTooltipY = y - rect.bottom;
      newTop = (parseFloat(targetSpan.style.top) || 0) + newTooltipY;
    } else {
      newTooltipY = rect.bottom - y;
      newTop = (parseFloat(targetSpan.style.top) || 0) - newTooltipY;
    }
    targetSpan.style.left = newLeft + 'px';
    targetSpan.style.top = newTop - 10 + 'px';
  }
};
